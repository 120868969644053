<template>
  <div class="container">
    <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
      <div class="list">
        <div v-for="item in list" :key="item.id" class="list-item van-hairline--bottom">
          <div class="list-item-num" :class="[item.type === 'add' ? 'ui-color' : 'ui-red']">{{ item.num }}</div>
          <div class="list-item-title">{{ item.name }}</div>
          <div class="list-item-txt">{{ item.create_at }}</div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'StudenCredits',
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 20,
      pageCurrent: 1
    }
  },
  created() {

  },
  methods: {
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.profile_credits({ 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    }
  }
}
</script>

<style lang="less" scoped>

  .list-item{
    position: relative;
    padding: 14px 15px;
    background: #fff;
    &-title{
      margin-bottom: 5px;
    }
    &-txt{
      font-size: 12px;
      color: #999;
    }
    &-num{
      position: absolute;
      top: 50%;
      right: 15px;
    }
  }
</style>

